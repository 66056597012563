import { mapGetters } from "vuex";

import * as jquery from 'jquery'
const $ = jquery

export const ReviewannotatorMixin = {
    data() {
        return {
            annotations: [],
        };
    },
    created() {},
    mounted() {
        this.reviewAnnotations();


    },
    filters: {},
    watch: {},
    computed: {
        __annotations() {
            return this.annotations;
        },

    },
    methods: {
        ...mapGetters([
            "getReviewparagraph",
        ]),
        reviewAnnotations() {
            try {
                this.annotations = []
                this.annotations = this.getReviewparagraph()
                this.mountSpans()
            } catch (e) {
                this.$tokentest(e.response.status)
                this.$toast.error('Ocorreu um erro ao carregar o parágrafo')
                this.loading = false

            }
        },

        mountSpans() {
            this.$nextTick(() => {
                let id = 0
                for (let j in this.annotations) {
                    this.annotations[j].user = this.annotations[j].user.map(val => {
                        val.text = this.annotations[j].text
                        let paragraph = val.text.split('')
                        for (let i in val.data) {
                            for (let it = 0; it <= paragraph.length; it++) {
                                if (val.data[i].start === it) {
                                    id = id + 1
                                    val.data[i]['id'] = id
                                    paragraph[it] = `<span class="${val.data[i].label}" style="${val.data[i]['color']}" start="${val.data[i].start}" end="${val.data[i].end}" id="${id}">${paragraph[it]}`
                                } else if (val.data[i].end === it) {
                                    if (paragraph[it] != undefined) {
                                        paragraph[it] = `</span>${paragraph[it]}`
                                    }

                                }


                            }
                        }

                        val.paragraph = paragraph.join('')
                        return val
                    })
                }
            })
        },


    },
};