<template>
  <div class="container-fluid annotator">
    <div class="container-fluid">
      <h1>Revisar anotação</h1>
      
     
      <div class="annotator__box">
        <transition-group name="fade">
          <template v-if="annotations.length > 0">
            <div
              class="annotator__paragraphs"
              v-for="(an, key) in __annotations"
              :key="key"
            >
              <template v-if="!an['finished']">
                <div
                  class="annotator__paragraphs__header not-finished--header"
                  
                >

                  <div class="paragraphs__header--info">
                    MATRÍCULA ID: {{ an.matricula_id }}
                  </div>
                  <div class="paragraphs__header--info">
                    PARAGRAPH ID: {{ an.paragraph_id }}
                  </div>
                </div>


                <div class="annotator__paragraphs__infos shadow">
                  <template>
                    <div
                      class="annotator__infos--text"
                      v-for="(us, key_) in an.user"
                      :key="key_"
                    >
                      <div class="annotator__infos--annotator">
                        Anotador: {{ us.name }}<br />
                      </div>

                      <div v-html="us.paragraph"></div>

                    </div>
                  </template>
                </div>
              </template>
            </div>
          </template>
        </transition-group>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { ReviewannotatorMixin } from "./Reviewannotator.mixin.js";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {};
  },
  watch: {},
  props: {},
  name: "Reviewannotator",
  mixins: [ReviewannotatorMixin],
};
</script>
<style src="./Reviewannotator.less" lang="less" />
